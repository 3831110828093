/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { ChevronDoubleUpIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { INotificationSurveyResponseJSON } from 'src/models'
import { Modal } from 'src/components/UI/Modal'
import { Button, ButtonStyle } from 'src/components/UI/Button'
import closeIcon from 'src/assets/Dashboard/close.svg'
import FirstStartedResponse from 'src/assets/Dashboard/shocked.svg'
import FirstCompletedResponse from 'src/assets/Dashboard/thumbs-up.svg'

function HighPriorityNotificationModal({
  onClose,
  notification,
}: {
  onClose?: () => void
  notification: INotificationSurveyResponseJSON
}): JSX.Element {
  const { t } = useTranslation('app')
  const [show, setShow] = useState(true)

  useEffect(() => {
    if (!show) {
      setTimeout(() => setShow(true), 500)
    }
  }, [show])

  const getMessages = surveyResponseStatus => {
    switch (surveyResponseStatus) {
      case 'started':
        return {
          title: t('notification.survey_response.first_started_response.title'),
          body: t(
            'notification.survey_response.first_started_response.content',
          ),
          imageSrc: FirstStartedResponse,
        }
      default: {
        // credits_earned statuses
        return {
          title: t('notification.survey_response.first_response.title'),
          body: t('notification.survey_response.first_response.content'),
          imageSrc: FirstCompletedResponse,
        }
      }
    }
  }

  const message = getMessages(notification.status)

  return (
    <Modal
      modalWithButton={false}
      modalOpen={true}
      modalHeight={100}
      modalWidth={600}
      modalHandler={onClose}
    >
      <div className='p-6'>
        <Button
          onClick={onClose}
          className='p-0 absolute right-4 top-4'
          style='text-black ml-auto'
        >
          <img src={closeIcon} alt='close-modal' width={32} />
        </Button>

        <div className='py-6 mb-4 px-8 flex flex-col items-center justify-center text-center'>
          <img
            src={message.imageSrc}
            height={100}
            width={100}
            className='mb-8'
          />

          <h2 className='text-2xl mb-3'>{message.title}</h2>

          <p>{message.body}</p>
        </div>

        <Button
          onClick={onClose}
          style={ButtonStyle.BLUE}
          className='w-full p-3 sm:px-6 sm:py-3'
        >
          Let&apos;s go
        </Button>
      </div>
    </Modal>
  )
}

export default HighPriorityNotificationModal
