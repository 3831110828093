import * as Sentry from '@sentry/react'
import { captureConsoleIntegration } from '@sentry/integrations'

const ignoredErrors = [
  // Our error messages
  /The string did not match the expected pattern/, // This error happens on Safari (iOS, iPhone), embedded browsers
  /ResizeObserver loop completed with undelivered notifications/, // Occurs on Safari
  /ResizeObserver loop limit exceeded/, // Occurs on Safari
  /Range\.setStart must be an instance of Node/, // Occurs on Safari
  /Argument 1 is not an object/, // Occurs on Safari
  /e is undefined/,
  /captured as promise rejection/,
  /promise rejection captured with value/,
  /The node to be removed is not a child of this node/,
  /Object captured as promise rejection with keys/, // 404 error on /sr/:code,
  /The object can not be found here/, // surveyjs itself
  /Failed to execute 'querySelectorAll' on 'Element'/, // surveyjs itself
  /Request failed with status code 404/,
  // values from https://docs.sentry.io/platforms/javascript/configuration/filtering/#using--1
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
]

export default {
  dsn:
    'https://85f49f4e358275ab632e8b0d5b372741@o4505432129339392.ingest.sentry.io/4506323911507968',
  normalizeDepth: 5,
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1,

  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      beforeErrorSampling: errorEvent => {
        console.log(errorEvent)
        if (errorEvent.request?.url?.includes('my-profile')) {
          return true
        }

        return !errorEvent.message?.includes('Loading CSS chunk')
      },
    }),
    captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  ignoreErrors: ignoredErrors,
  beforeSend(event, hint) {
    if (hint.originalException === 'Timeout') return null
    return event
  },
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /translate\.googleapis\.com/i,
  ],
} as Sentry.BrowserOptions
