/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Route,
  Redirect,
  Switch,
  RouteProps,
  useLocation,
} from 'react-router-dom'
import React, { useContext, useEffect, useRef, useState } from 'react'

const NoMatch = React.lazy(() => import('./404/404'))
const AuthRoute = React.lazy(() => import('./auth/AuthRoute'))
const SignInRoute = React.lazy(() => import('./sign-in/SignInRoute'))
const SignUpRoute = React.lazy(() => import('./sign-up/SignUpRoute'))
const ForgotPasswordRoute = React.lazy(
  () => import('./forgot-password/ForgotPasswordRoute'),
)
const ConfirmMigrationRoute = React.lazy(
  () => import('./confirm-migration/ConfirmMigrationRoute'),
)

const HomeRoute = React.lazy(() => import('./home/HomeRoute'))
const QuoteRoute = React.lazy(() => import('./quote/QuoteRoute'))
const AgencyRoute = React.lazy(() => import('./agency/AgencyRoute'))
const LcdhqRoute = React.lazy(() => import('./lcdhq/LcdhqRoute'))
const LandingPaidRoute = React.lazy(() => import('./paid/PaidRoute'))
const SurveysRoute = React.lazy(() => import('./surveys/SurveysRoute'))
const DashboardRoute = React.lazy(() => import('./dashboard/DashboardRoute'))
const MyProfileRoute = React.lazy(() => import('./my-profile/MyProfileRoute'))
const AddSurveyRoute = React.lazy(() => import('./add-survey/AddSurveyRoute'))
const ListSurveyRoute = React.lazy(() => import('./add-survey/ListSurveyRoute'))
// const OnboardingRoute = React.lazy(() => import('./onboarding/OnboardingRoute'))
const CompleteSurveyRoute = React.lazy(() => import('./sr/CompleteSurveyRoute'))
const LandingStudentsRoute = React.lazy(
  () => import('./students/StudentsRoute'),
)
const LandingAboutRoute = React.lazy(() => import('./about/AboutRoute'))
const DisclaimerRoute = React.lazy(() => import('./disclaimer/DisclaimerRoute'))
// const GetStartedRoute = React.lazy(() => import('./get-started/GetStartedRoute'))
const PrivacyPolicyRoute = React.lazy(
  () => import('./privacy-policy/PrivacyPolicyRoute'),
)
const InvitationGetStartedRoute = React.lazy(
  () => import('./get-started/InvitationGetStartedRoute'),
)
const TermsAndConditionsRoute = React.lazy(
  () => import('./terms-and-conditions/TermsAndConditionsRoute'),
)
const ShareSurveyRoute = React.lazy(
  () => import('./share-survey/ShareSurveyRoute'),
)
const WelcomeRoute = React.lazy(() => import('./Welcome/WelcomeRoute'))
const GetCreditsRoute = React.lazy(
  () => import('./get-credits-route/GetCreditsRoute'),
)
const MySurveysRoute = React.lazy(() => import('./my-surveys/MySurveysRoute'))
const SurveySchemasRoute = React.lazy(
  () => import('./survey-schemas/SurveySchemasRoute'),
)

const CreateSurveySchemaRoute = React.lazy(
  () => import('./survey-schemas/CreateSurveySchemaRoute'),
)
const EditSurveySchemaRoute = React.lazy(
  () => import('./survey-schemas/EditSurveySchemaRoute'),
)
const SurveySchemaFormRoute = React.lazy(
  () => import('./survey-schemas/SurveySchemaFormRoute'),
)
const UserSegmentRoute = React.lazy(
  () => import('./user-segment/UserSegmentRoute'),
)

const TakeSurveyRoute = React.lazy(
  () => import('./surveys/[id1]/[id2]/take-a-survey/TakeSurveyRoute'),
)
const MyProfileSurveyRoute = React.lazy(
  () => import('./surveys/MyProfileSurveyRoute'),
)

const SurveyInsightsRoute = React.lazy(
  () => import('./survey-insights/SurveyInsightsRoute'),
)

// Miscellaneous
import i18n from '../I18n'
import Variants from '../config/variants'
import { CurrentUserContext } from '../App'

import BuyCreditsRoute from './buy-credits/BuyCreditsRoute'

import { AppLoader } from '../components/AppLoader'
import {
  freeGoogleAdVariants,
  paidGoogleAdVariants,
} from '../config/adVariants'
import RedirectComponent from 'src/containers/RedirectComponent'
import DataQualityStatement from 'src/containers/DataQualityStatement'
const SurveyRankingRoute = React.lazy(
  () => import('src/routes/survey-ranking/SurveyRankingRoute'),
)

// import { includeCurrentLocaleToPath } from '../helpers/localeHelpers'

const PaidSurveyExchangeRoute = React.lazy(
  () => import('./paid-survey-exchange/PaidSurveyExchangeRoute'),
)

const StudentsNewRoute = React.lazy(
  () => import('./students-new/StudentsNewRoute'),
)

const HomeNewRoute = React.lazy(() => import('./home-new-route/HomeNewRoute'))

const ProtectedRoute = ({
  component: Component,
  computedMatch: match,
  ...rest
}: any) => {
  const verified = localStorage.getItem('loggedin')
  const { currentUser } = useContext(CurrentUserContext)

  if (currentUser && currentUser.status === 'blocked') {
    return <Redirect to='/' />
  }

  if (location.pathname === '/surveys' && (!verified || verified !== 'yes')) {
    return <Redirect to='/' />
  }

  if (!verified || verified !== 'yes') {
    return <Redirect to='/sign-up' />
  }

  // See https://v5.reactrouter.com/web/api/Route/render-func
  return (
    <Route
      {...rest}
      render={() => (
        <React.Suspense fallback={AppLoader}>
          <Component match={match} {...rest} />
        </React.Suspense>
      )}
    />
  )
}

const NotLoggedInRoute = ({
  component: Component,
  computedMatch: match,
  ...rest
}: any) => {
  const verified = localStorage.getItem('loggedin')
  if (verified && verified === 'yes') {
    return <Redirect to='/get-credits' />
  }
  return (
    <React.Suspense fallback={AppLoader}>
      <Component match={match} {...rest} />
    </React.Suspense>
  )
}

const isLangInURL = (pathname: string, langToDetect: string) => {
  return (
    pathname.startsWith(`/${langToDetect}/`) ||
    pathname.startsWith(`/${langToDetect}#`) ||
    pathname.startsWith(`/${langToDetect}?`) ||
    pathname === `/${langToDetect}`
  )
}

const shouldChangeLang = (pathname: string, langToDetect: string) => {
  return isLangInURL(pathname, langToDetect) && i18n.language !== langToDetect
}

const RouteHoc = (
  routeProps: RouteProps & { type?: 'protected' | 'notLoggedIn' },
) => {
  const [afterFirstRender, markAfterFirstRender] = useState(false)
  const { pathname, hash } = useLocation()

  if (shouldChangeLang(pathname, 'de')) {
    i18n.changeLanguage('de', err => {
      if (err) return console.log('something went wrong loading', err)
    })
  } else if (shouldChangeLang(pathname, 'nl')) {
    i18n.changeLanguage('nl', err => {
      if (err) return console.log('something went wrong loading', err)
    })
  } else if (
    i18n.language !== 'en' &&
    !isLangInURL(pathname, 'de') &&
    !isLangInURL(pathname, 'nl')
  ) {
    i18n.changeLanguage('en', err => {
      if (err) return console.log('something went wrong loading', err)
    })
  }

  /**
   * Smooth scrool to the section when an anchor link is used.
   */
  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0)
    } else {
      const delay = afterFirstRender ? 0 : 500
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, delay)
    }
    markAfterFirstRender(true)
  }, [pathname, hash])

  const currentBeacon = useRef(null)

  useEffect(() => {
    const initBeacon = beaconId => {
      if (currentBeacon.current !== beaconId) {
        const initializeBeacon = () => {
          console.log('Initializing new Beacon...')
          window.Beacon('init', beaconId)
          currentBeacon.current = beaconId
        }

        if (typeof window.Beacon === 'function') {
          if (currentBeacon.current) {
            // If there was a previous beacon
            console.log('Destroying existing Beacon...')
            window.Beacon('destroy')

            setTimeout(initializeBeacon, 500) // Delay the initialization by 500ms after destroy
          } else {
            initializeBeacon() // If it's the first time initialization
          }
        }
      } else {
        console.log('Desired Beacon is already initialized. Doing nothing.')
      }
    }

    console.log('Checking window.Beacon...')
    // If window.Beacon is already a function, initialize it directly.
    if (typeof window.Beacon === 'function') {
      console.log('window.Beacon is available.')
      if (routeProps.location.pathname === '/buy-credits') {
        console.log(
          'Path is /buy-credits. Trying to initialize respective Beacon.',
        )
        initBeacon('fccae2a0-2a66-42e3-9c23-def1dd9ac343')
      } else {
        console.log(
          'Path is not /buy-credits. Trying to initialize default Beacon.',
        )
        initBeacon('0b7f494a-9681-4db2-a84c-0d2152a4fba0')
      }
    } else {
      console.log(
        'window.Beacon is not yet available. Setting an interval to check...',
      )
      // If window.Beacon isn't available yet, check periodically.
      const interval = setInterval(() => {
        if (typeof window.Beacon === 'function') {
          console.log('window.Beacon is now available inside interval.')
          if (routeProps.location.pathname === '/buy-credits') {
            console.log(
              'Path is /buy-credits (inside interval). Trying to initialize respective Beacon.',
            )
            initBeacon('fccae2a0-2a66-42e3-9c23-def1dd9ac343')
          } else {
            console.log(
              'Path is not /buy-credits (inside interval). Trying to initialize default Beacon.',
            )
            initBeacon('0b7f494a-9681-4db2-a84c-0d2152a4fba0')
          }
          clearInterval(interval)
        }
      }, 100) // check every 100ms

      return () => {
        console.log('Cleanup: Clearing interval.')
        clearInterval(interval) // Cleanup on unmount
      }
    }
  }, [routeProps.location.pathname])

  // if (routeProps.location.pathname === '/dashboard') {
  //   history.push('/get-credits')
  // }

  if (routeProps.type === 'protected') {
    return <ProtectedRoute {...routeProps} />
  }
  if (routeProps.type === 'notLoggedIn') {
    return <NotLoggedInRoute {...routeProps} />
  }

  return (
    <React.Suspense fallback={AppLoader}>
      <Route {...routeProps} />
    </React.Suspense>
  )
}

const langs = '/:lang(de|nl)?'
const routes = (
  <Switch>
    <RouteHoc exact path={`${langs}/`} component={HomeRoute} />
    <RouteHoc
      exact
      path={`${langs}/students`}
      component={LandingStudentsRoute}
    />
    <RouteHoc exact path={`${langs}/paid`} component={LandingPaidRoute} />
    {/* Slightly different approach to variant array as below, because of different use cases */}
    {paidGoogleAdVariants.map(variant => (
      <RouteHoc
        exact
        key={variant}
        path={`${langs}/${variant}`}
        component={LandingPaidRoute}
      />
    ))}
    <RouteHoc
      exact
      path={`${langs}/paid-survey-exchange`}
      component={PaidSurveyExchangeRoute}
    />
    <RouteHoc exact path={`${langs}/home-new`} component={HomeNewRoute} />
    <RouteHoc
      exact
      path={`${langs}/students-new`}
      component={StudentsNewRoute}
    />
    {freeGoogleAdVariants.map(variant => (
      <RouteHoc
        exact
        key={variant}
        path={`${langs}/${variant}`}
        component={LandingStudentsRoute}
      />
    ))}
    {Variants.paid.map(variant => (
      <RouteHoc
        exact
        key={variant.path}
        path={variant.path}
        component={LandingPaidRoute}
      />
    ))}
    {Variants.students.map(variant => (
      <RouteHoc
        exact
        key={variant.path}
        path={variant.path}
        component={LandingStudentsRoute}
      />
    ))}
    <RouteHoc exact path={`${langs}/quote`} component={QuoteRoute} />
    <RouteHoc exact path={`${langs}/agency`} component={AgencyRoute} />

    <RouteHoc exact path={`${langs}/about`} component={LandingAboutRoute} />
    <RouteHoc
      exact
      path={`${langs}/redirect-to-finalise`}
      component={RedirectComponent}
    />
    <RouteHoc
      exact
      path={`${langs}/data-quality-statement`}
      component={DataQualityStatement}
    />
    <RouteHoc
      exact
      path={`${langs}/terms-and-conditions`}
      component={TermsAndConditionsRoute}
    />
    <RouteHoc exact path={`${langs}/disclaimer`} component={DisclaimerRoute} />
    <RouteHoc
      exact
      path={`${langs}/privacy-policy`}
      component={PrivacyPolicyRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={[
        `${langs}/dashboard`,
        `${langs}/get-credits`,
        `${langs}/my-surveys`,
        `${langs}/list-survey`,
        `${langs}/survey-schemas`,
        `${langs}/survey-insights`,
        `${langs}/insights`,
      ]}
      component={SurveyRankingRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/add-survey`}
      component={AddSurveyRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/my-profile`}
      component={MyProfileRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/surveys`}
      component={SurveysRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/lcdhq/:completion`}
      component={LcdhqRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/share-survey`}
      component={ShareSurveyRoute}
    />

    <RouteHoc
      type='notLoggedIn'
      exact
      path={`${langs}/auth`}
      component={AuthRoute}
    />
    <RouteHoc
      type='notLoggedIn'
      exact
      path={`${langs}/sign-in`}
      component={SignInRoute}
    />
    <RouteHoc
      type='notLoggedIn'
      exact
      path={`${langs}/sign-up`}
      component={SignUpRoute}
    />
    <RouteHoc
      type='notLoggedIn'
      exact
      path={`${langs}/forgot-password`}
      component={ForgotPasswordRoute}
    />
    <RouteHoc
      type='notLoggedIn'
      exact
      path={`${langs}/confirm-migration`}
      component={ConfirmMigrationRoute}
    />
    <RouteHoc
      // type='protected'
      path={`${langs}/sr/:code`}
      component={CompleteSurveyRoute}
    />
    {/* <RouteHoc path={`${langs}/onboarding`} component={OnboardingRoute} />
    <RouteHoc path={`${langs}/get-started`} component={GetStartedRoute} /> */}
    <RouteHoc
      path={`${langs}/invitations`}
      component={InvitationGetStartedRoute}
    />
    <RouteHoc
      exact
      path={`${langs}/surveys/:id1/:id2?/take-a-survey`}
      component={TakeSurveyRoute}
    />
    <RouteHoc
      type='protected'
      path={`${langs}/surveys/my-profile`}
      component={MyProfileSurveyRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/welcome`}
      component={WelcomeRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={[`${langs}/get-credits`, `${langs}/get-respondents`]}
      component={GetCreditsRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={[`${langs}/my-surveys`, `${langs}/list-survey`]} // list-survey for not return 401 page when clicking breadcrums in /list-survey/:id
      component={MySurveysRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/user-segment`}
      component={UserSegmentRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/user-segment`}
      component={UserSegmentRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/welcome-p`}
      component={SurveyRankingRoute}
    />
    <RouteHoc exact path={`${langs}/buy-credits`} component={BuyCreditsRoute} />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/survey-schemas`} // should rename to my-surveys, the current my-surveys will be renamed to listed-surveys
      component={SurveySchemasRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/survey-schemas/:id/edit`}
      component={EditSurveySchemaRoute}
    />
    <RouteHoc
      exact
      path={`${langs}/create-a-survey`}
      component={CreateSurveySchemaRoute}
    />
    <RouteHoc
      exact
      path={`${langs}/create-a-survey-sign-in-local`}
      component={CreateSurveySchemaRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/list-survey/:surveySchemaId`}
      component={ListSurveyRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/survey-insights`}
      component={SurveyInsightsRoute}
    />
    {/* TODO use slug in place of id */}
    <RouteHoc
      exact
      path={`${langs}/forms/:id`}
      component={SurveySchemaFormRoute}
    />
    <RouteHoc
      type='protected'
      exact
      path={`${langs}/survey-ranking`}
      component={SurveyRankingRoute}
    />
    <RouteHoc exact component={NoMatch} />
  </Switch>
)
export default routes
