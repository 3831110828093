import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { includeCurrentLocaleToPath } from '../../../helpers/localeHelpers'

export interface IButtonProps {
  children?: React.ReactNode
  type?: 'button' | 'submit'
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  style?: ButtonStyle | string
  iconPosition?: 'left' | 'right'
  icon?: string
  target?: '_blank' | '_parent' | '_self' | '_top'
  href?: string
  disabled?: boolean
  asReactLink?: boolean
  supportLocale?: boolean
  id?: string
  subText?: string
}

export enum ButtonStyle {
  PRIMARY = 'flex items-center justify-center bg-black text-white rounded-md hover:shadow-md hover:bg-blue-500 border-1 border-black hover:border-blue-500',
  SECONDARY = 'flex items-center justify-center bg-gray-700 text-black rounded-md border border-gray-600 hover:bg-blue-700 hover:border-blue-600 hover:text-blue-500',
  TERTIARY = 'font-normal flex items-center justify-center bg-gray-700 text-black rounded-md border border-black hover:bg-black hover:text-white',
  WHITE = 'flex items-center justify-center border-1 border-black bg-white text-black rounded-md hover:bg-black hover:text-white',
  NOBORDERS = 'flex items-center justify-center border-0 bg-white text-black opacity-70 hover:opacity-100 hover:text-blue-500',
  BLUE = 'flex items-center justify-center bg-blue-500 hover:bg-blue-400 text-white rounded-md',
  ORANGE = 'flex items-center justify-center bg-orange-500 hover:bg-orange-400 text-white rounded-md',
  DISABLED = 'cursor-not-allowed text-gray-100 flex items-center justify-center bg-gray-300 rounded-md ',
  YELLOW = 'flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-black rounded-md',
  GRADIENT = 'flex items-center justify-center bg-black text-white rounded-xl relative',
}

export const Button: React.FunctionComponent<IButtonProps> = ({
  type = 'button',
  className = 'max-w-max p-3 sm:px-6 sm:py-3',
  onClick,
  style = ButtonStyle.PRIMARY,
  iconPosition = 'left',
  icon,
  target,
  id,
  href,
  asReactLink = true,
  children,
  disabled = false,
  supportLocale = true,
  subText,
}) => {
  const transitionStyle = 'transition duration-200 ease-in-out'
  const isGradient = style === ButtonStyle.GRADIENT

  // Handle standard button styles
  const buttonStyle = classNames(
    { [ButtonStyle.DISABLED]: disabled },
    { [style]: !disabled },
    'font-semibold',
    transitionStyle,
    {
      ['flex-row-reverse']: iconPosition === 'right',
      [className]: className,
    },
  )

  const imageStyle = classNames('min-w-5 w-5 h-5', {
    ['md:ml-3']: children && iconPosition === 'right',
    ['md:mr-3']: children && iconPosition === 'left',
  })

  // Prepare content for standard button
  const buttonContent = (
    <>
      {icon && <img className={imageStyle} src={icon} alt={icon} />}
      {children && children}
    </>
  )

  // Prepare content for gradient button
  const gradientButtonContent = (
    <>
      <div className='relative inline-flex'>
        {/* Gradient border */}
        <div className='absolute -inset-2'>
          <div
            className='w-full h-full mx-auto opacity-30 blur-lg filter'
            style={{
              background:
                'linear-gradient(90deg, #44BCFF -0.55%, #FF44EC 48.36%, #FF675E 99.34%)',
            }}
          />
        </div>
        {/* Button content (maintaining the original implementation's structure) */}
        {buttonContent}
      </div>
      {subText && (
        <div className='text-sm font-light italic mt-4'>{subText}</div>
      )}
    </>
  )

  // Determine which content to use
  const content = isGradient ? gradientButtonContent : buttonContent

  if (href) {
    if (asReactLink) {
      let linkhref = href
      if (supportLocale) {
        linkhref = includeCurrentLocaleToPath(href)
      }
      if (linkhref.includes('#')) {
        return (
          <HashLink
            smooth={true}
            id={id}
            className={buttonStyle}
            to={linkhref}
            target={target}
          >
            {content}
          </HashLink>
        )
      }
      return (
        <Link id={id} className={buttonStyle} to={linkhref} target={target}>
          {content}
        </Link>
      )
    }
    return (
      <a id={id} className={buttonStyle} href={href} target={target}>
        {content}
      </a>
    )
  }

  return (
    <button
      onClick={e => onClick && onClick(e)}
      className={buttonStyle}
      type={type}
      disabled={disabled}
      id={id}
    >
      {content}
    </button>
  )
}
