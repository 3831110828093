import { Helmet } from 'react-helmet-async'

export interface IMetaTagsProps {
  title?: string
  description?: string
  lang?: string
  type?: string
  image?: string
}

export const MetaTags = ({
  title = 'SurveySwap | Find Survey Participants Today',
  description = "SurveySwap is the #1 solution for surveys. ou can instantly connect your survey, and get free survey respondents for yourself by filling out other people's surveys.",
  type = 'website',
  image = 'https://surveyswap-assets.s3.eu-central-1.amazonaws.com/images/surveyswap-welcome-img.png',
  lang = 'en',
}: IMetaTagsProps): JSX.Element => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {lang && <html lang={lang} />}
      {description && <meta name='description' content={description} />}
      {title && <meta property='og:title' content={title} />}
      {description && <meta property='og:description' content={description} />}
      {image && <meta property='og:image' content={image} />}
      {type && <meta property='og:type' content={type} />}
    </Helmet>
  )
}
