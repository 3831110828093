export type TCurrencies = 'USD' | 'EUR' | 'GBP'

/**
 * The new Plan interface
 */
export interface IPlan {
  id?: string
  plan_name: string
  label?: string
  plan_priority?: number
  credits: number
  price_eur: number
  price_usd: number
  price_gbp: number
  discount_eur: number
  discount_usd: number
  discount_gbp: number
  discount_rate: number
  total_eur: number
  total_usd: number
  total_gbp: number
  respondents?: number
  respondentsPercentage?: number
  isPopular?: boolean
  packageKey?: string // <-- Add this line
}

/**
 * IPlanPrices
 */
export interface IPlanPrices {
  price: number
  discount: number
  discount_rate: number
  total: number
  currency: TCurrencies
}

/**
 * The Plan model.
 */
export class Plan {
  id?: string
  plan_name: string
  label?: string
  plan_priority?: number
  credits: number
  price_eur: number
  price_usd: number
  price_gbp: number
  discount_eur: number
  discount_usd: number
  discount_gbp: number
  discount_rate: number
  total_eur: number
  total_usd: number
  total_gbp: number
  respondents?: number
  respondentsPercentage?: number
  isPopular?: boolean
  packageKey?: string

  constructor(props: IPlan) {
    this.id = props.id
    this.plan_name = props.plan_name
    this.credits = props.credits
    this.price_eur = props.price_eur
    this.price_usd = props.price_usd
    this.price_gbp = props.price_gbp
    this.discount_eur = props.discount_eur
    this.discount_usd = props.discount_usd
    this.discount_gbp = props.discount_gbp
    this.discount_rate = props.discount_rate
    this.total_eur = props.total_eur
    this.total_usd = props.total_usd
    this.total_gbp = props.total_gbp
    this.respondents = props.respondents
    this.respondentsPercentage = props.respondentsPercentage
    this.isPopular = props.isPopular
  }

  /**
   * Returns the price in a given currency. If currency not found, returns
   * price in EUR.
   * @param {TCurrencies} currency
   */
  getPriceInCurrency(currency: TCurrencies): number {
    switch (currency) {
      case 'USD':
        return this.price_usd
      case 'GBP':
        return this.price_gbp
      default:
        return this.price_eur
    }
  }

  /**
   * Returns the discount amount in a given currency. If currency not found, returns
   * amount in EUR.
   * @param {TCurrencies} currency
   */
  getDiscountInCurrency(currency: TCurrencies): number {
    switch (currency) {
      case 'USD':
        return this.discount_usd
      case 'GBP':
        return this.discount_gbp
      default:
        return this.discount_eur
    }
  }

  /**
   * Returns the total price in a given currency. If currency not found, returns
   * price in EUR.
   * @param {TCurrencies} currency
   */
  getTotalInCurrency(currency: TCurrencies): number {
    switch (currency) {
      case 'USD':
        return this.total_usd
      case 'GBP':
        return this.total_gbp
      default:
        return this.total_eur
    }
  }

  /**
   * Returns all prices in a given currency. If currency not found, returns in EUR.
   * @param {TCurrencies} currency
   */
  getPricesInCurrency(currency: TCurrencies): IPlanPrices {
    switch (currency) {
      case 'USD':
        return {
          price: this.price_usd,
          discount: this.discount_usd,
          discount_rate: this.discount_rate,
          total: this.total_usd,
          currency: 'USD',
        }
      case 'GBP':
        return {
          price: this.price_gbp,
          discount: this.discount_gbp,
          discount_rate: this.discount_rate,
          total: this.total_gbp,
          currency: 'GBP',
        }
      default:
        return {
          price: this.price_eur,
          discount: this.discount_eur,
          discount_rate: this.discount_rate,
          total: this.total_eur,
          currency: 'EUR',
        }
    }
  }
}
