import Reviews from 'src/components/ReviewWidget/Reviews'
import { withReviewsConfiguration } from 'src/hoc/withReviewsConfiguration'
import { SecurePayments } from './SelectPlans'
import academic4 from 'src/assets/LandingPage/Home/4.webp'
import academic5 from 'src/assets/LandingPage/Home/5.webp'
import academic6 from 'src/assets/LandingPage/Home/6.webp'
import academic8 from 'src/assets/LandingPage/Home/8.webp'
import FaqBuyExchange from './FaqBuyExchange'
import cambridgeLogo from 'src/assets/BuyCredits/cambridge-small.png'
import oxfordLogo from 'src/assets/BuyCredits/oxford-small.png'
import yaleLogo from 'src/assets/BuyCredits/Yale_University-Logo.wine.svg'
import berkleyLogo from 'src/assets/BuyCredits/Berkley.png'
import uvaLogo from 'src/assets/BuyCredits/UVA-small.png'
import cologneLogo from 'src/assets/BuyCredits/university-cologne.jpg'
import pwcLogo from 'src/assets/BuyCredits/pwc-small.png'
import mercedesLogo from 'src/assets/BuyCredits/mercedez small.jpeg'
import pgLogo from 'src/assets/BuyCredits/Procter-Gamble.jpg'
import mitLogo from 'src/assets/LandingPage/Home/mit_logo.webp'

const LogoGrid = () => {
  const logos = [
    { src: cambridgeLogo, alt: 'University of Cambridge' },
    { src: oxfordLogo, alt: 'University of Oxford' },
    { src: yaleLogo, alt: 'Yale University' },
    { src: berkleyLogo, alt: 'UC Berkeley' },
    { src: uvaLogo, alt: 'University of Amsterdam' },
    { src: cologneLogo, alt: 'University of Cologne' },
    { src: pwcLogo, alt: 'PwC' },
    { src: mercedesLogo, alt: 'Mercedes-Benz' },
    { src: pgLogo, alt: 'Procter & Gamble' },
    { src: mitLogo, alt: 'MIT University' },
  ]

  return (
    <div className='w-fullpy-12 bg-white'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='text-center mb-8'>
          <h2 className='text-3xl font-semibold text-gray-900'>
            Trusted by leading institutions
          </h2>
        </div>

        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 items-center justify-items-center'>
          {logos.map((logo, index) => (
            <div key={index} className='flex items-center justify-center p-4'>
              <img
                src={logo.src}
                alt={logo.alt}
                className='max-h-12 w-auto object-contain grayscale hover:grayscale-0 transition-all duration-300'
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const Statistics = ({
  reviewsConfiguration = {},
}: {
  reviewsConfiguration: any
}): JSX.Element => {
  const logos = [
    { src: cambridgeLogo, alt: 'University of Cambridge' },
    { src: oxfordLogo, alt: 'University of Oxford' },
    { src: yaleLogo, alt: 'Yale University' },
    { src: berkleyLogo, alt: 'UC Berkeley' },
    { src: uvaLogo, alt: 'University of Amsterdam' },
    { src: cologneLogo, alt: 'University of Cologne' },
    { src: pwcLogo, alt: 'PwC' },
    { src: mercedesLogo, alt: 'Mercedes-Benz' },
    { src: pgLogo, alt: 'Procter & Gamble' },
    { src: mitLogo, alt: 'MIT University' },
  ]

  return (
    <>
      <section className='py-10 sm:py-16 lg:py-20'>
        <div className='px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl'>
          <div className='max-w-3xl mx-auto text-center'>
            <h2 className='text-3xl font-semibold tracking-tight sm:text-4xl lg:text-5xl'>
              Why SurveySwap
            </h2>
          </div>

          {/* Logos Grid */}
          <div className='mt-12 lg:mt-16'>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 items-center justify-items-center'>
              {logos.map((logo, index) => (
                <div
                  key={index}
                  className='flex items-center justify-center p-4'
                >
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    className='max-h-12 w-auto object-contain grayscale hover:grayscale-0 transition-all duration-300'
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Stats Section */}
          <div className='relative mt-12 lg:mt-20 lg:max-w-4xl lg:mx-auto'>
            <div className='absolute -inset-2'>
              <div
                className='w-full h-full mx-auto opacity-30 blur-lg filter'
                style={{
                  background:
                    'linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)',
                }}
              ></div>
            </div>
            <div className='relative grid grid-cols-1 px-16 py-12 overflow-hidden text-center bg-white sm:grid-cols-2 lg:grid-cols-3 gap-y-12 rounded-2xl gap-x-16'>
              <div className='flex flex-col items-center'>
                <p className='text-5xl font-bold lg:mt-3 lg:order-2 font-pj'>
                  2M+
                </p>
                <h3 className='mt-5 text-sm font-bold tracking-widest uppercase lg:mt-0 lg:order-1 font-pj whitespace-pre-line'>
                  Surveys{'\n'}Swapped
                </h3>
              </div>
              <div className='flex flex-col items-center'>
                <p className='text-5xl font-bold lg:mt-3 lg:order-2 font-pj'>
                  1500+
                </p>
                <h3 className='mt-5 text-sm font-bold tracking-widest uppercase lg:mt-0 lg:order-1 font-pj whitespace-pre-line'>
                  Weekly{'\n'} Active Surveys
                </h3>
              </div>
              <div className='flex flex-col items-center'>
                <p className='text-5xl font-bold lg:mt-3 lg:order-2 font-pj'>
                  5000+
                </p>
                <h3 className='mt-5 text-sm font-bold tracking-widest uppercase lg:mt-0 lg:order-1 font-pj whitespace-pre-line'>
                  Universities{'\n'}Worldwide
                </h3>
              </div>
            </div>
          </div>

          {/* Reviews Section */}
          <section className='bg-white mt-20'>
            <div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
              <Reviews
                withHeader={false}
                reviews={reviewsConfiguration.reviewContent?.landingPaidReviews}
              />
            </div>
          </section>
        </div>
      </section>
      <FaqBuyExchange></FaqBuyExchange>
    </>
  )
}
export default withReviewsConfiguration(Statistics)
