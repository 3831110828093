const colors = {
  glow: {
    blue: '#44BCFF',
    pink: '#FF44EC',
    red: '#FF675E',
  },
  black: '#000000',
  white: '#ffffff',
  yellow: {
    500: '#FFC107', // Original yellow
    600: '#FFD700', // Original yellow
    700: '#FFEA80', // Muted warm yellow
    800: '#FFEEA6', // Lighter muted warm yellow
  },
  red: {
    500: '#ec4646',
    600: '#FF3232',
  },
  darkRed: {},
  blue: {
    400: '#363FCC',
    500: '#3F4AF0',
    600: '#C9D2FF',
    700: '#EAEDFC',
  },
  darkGray: {
    200: '#1a1a1a',
  },
  gray: {
    50: '#bebebe',
    100: '#767676',
    200: '#e7e8ee',
    300: '#ebebeb',
    400: '#F5F5F5',
    500: '#EDF1F3',
    600: '#f2f2f2',
    700: '#fafafa',
    800: '#edeff8',
    900: '#919EAB1F',
  },
  newGray: {
    700: '#98A0B4',
  },
  green: {
    400: '#32C08D',
    500: '#ADF0DC',
    600: '#B4F2DF',
    700: '#E9FDF7',
  },
  orange: {
    300: '#FFB29A',
    400: '#E65F2D',
    500: '#FF6932',
    600: '#FFCBBB',
    700: '#FFEFEA',
    800: '#F7B267',
  },
  brands: {
    facebook: '#3b5998',
  },
  brownRed: {
    700: '#B71D18',
  },
  karma: {
    light: '#FF563014',
  },
}

const fontFamily = {
  sans: ['Inter', 'sans-serif'],
  serif: ['Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
  mono: [
    'Menlo',
    'Monaco',
    'Consolas',
    '"Liberation Mono"',
    '"Courier New"',
    'monospace',
  ],
  caveat: ['Caveat', 'sans-serif'], // TODO: add font Caveat
}

const fontSize = {
  '2xs': '0.66rem',
  '3xs': '0.5rem',
}

const maxWidth = {
  '1/4': '25%',
  '1/2': '50%',
  '3/4': '75%',
  '5/12': '42%',
  '7/12': '58%',
  '840px': '840px',
  screen: '100vw',
}

const commonWidths = {
  1: '0.25rem',
  1.5: '0.375rem',
  5: '1.25rem',
  8: '2rem',
  10: '2.5rem',
  12: '3rem',
  32: '8rem',
  96: '24rem',
  102: '26rem',
  '400px': '400px',
  '596px': '596px',
  '600px': '600px',
}

const shadows = {
  sm: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  DEFAULT: '0px 4px 18px rgba(153, 156, 180, 0.23)',
  md: '0px 4px 15px rgba(153, 156, 180, 0.25)',
  lg: '0px 4px 30px rgba(153, 156, 180, 0.3)',
  xl:
    '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  '3xl': '0 35px 60px -15px rgba(0, 0, 0, 0.3)',
  inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  none: 'none',
  input: '0px 0px 12px rgba(63, 74, 240, 0.1)',
}

const scales = {
  115: '1.15',
  120: '1.20',
}

const screens = {
  xs: '380px',
  // => @media (min-width: 380px) { ... }

  sm: '640px',
  // => @media (min-width: 640px) { ... }

  md: '768px',
  // => @media (min-width: 768px) { ... }

  lg: '1024px',
  // => @media (min-width: 1024px) { ... }

  xl: '1280px',
  // => @media (min-width: 1280px) { ... }

  '2xl': '1536px',
  // => @media (min-width: 1536px) { ... }
}

const spacing = {
  112: '28rem',
  '400px': '400px',
  'floating-right-box': '330px',
}

const rotate = {
  45: '45deg',
  '-70': '-70deg',
  '-45': '-45deg',
}

const backgroundOpacity = {
  8: '0.08',
}

module.exports = {
  colors,
  commonWidths,
  fontFamily,
  fontSize,
  maxWidth,
  scales,
  screens,
  shadows,
  spacing,
  rotate,
  backgroundOpacity,
}
